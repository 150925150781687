<template>
  <div class="code-view">
    <div class="code-view--top">
      <!-- <h5>Card with photo</h5> -->
      <div id="codeBtn" class="code-button" @click="handleCodeRequest">
        Code
      </div>
    </div>
    <div class="code-view--bottom" v-if="showCode">
      <div class="tab-container">
        <ul style="max-width:inherit">
          <li
            @click="setActiveTab('html')"
            :class="{ active: activeTab === 'html' }"
          >
            HTML
          </li>
          <!--<li
            @click="setActiveTab('css')"
            :class="{ active: activeTab === 'css' }"
          >
            STYLES
          </li>
          <li
            @click="setActiveTab('js')"
            :class="{ active: activeTab === 'js' }"
          >
            JS
          </li> -->
          <li style="width: 10%; color: white" @click="handleCopy">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-copy"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
              <path
                d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
              ></path>
            </svg>
          </li>
        </ul>
      </div>

      <div class="code-highlight" v-if="id === null">
        <div v-if="activeTab === 'html'">
          <Prism language="html">{{
            componentsObject.comingComponent.html
          }}</Prism>
        </div>
        <!--<div v-if="activeTab === 'css'">
          <Prism language="css">{{
            componentsObject.comingComponent.css
          }}</Prism>
        </div>
        <div v-if="activeTab === 'js'">
          <Prism language="javascript">{{
            componentsObject.comingComponent.js
          }}</Prism>
        </div> -->
      </div>
      <div class="code-highlight" v-else>
        <div v-if="activeTab === 'html'">
          <Prism language="html" v-if="dataFethed">{{
            responseObjectMapping[this.type]
          }}</Prism>
          <div v-else>
            <div class="spinner spinner-lrg spinner-white"></div>
          </div>
        </div>
        <!-- <div v-if="activeTab === 'html'">
          <Prism language="html">{{ componentsObject[this.id].html }}</Prism>
        </div> -->
        <!--<div v-if="activeTab === 'css'">
          <Prism language="css">{{ componentsObject[this.id].css }}</Prism>
        </div>
        <div v-if="activeTab === 'js'">
          <Prism language="javascript">{{
            componentsObject[this.id].js
          }}</Prism>
        </div> -->
      </div>
    </div>
    <div class="preview">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import "prismjs";
import axios from "axios";
import "prismjs/themes/prism.css";
import Prism from "vue-prism-component";

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
export default {
  name: "CodeView",
  props: {
    component: {
      type: String,
      default: "",
    },
    type: String,
    block: {
      type: String,
      default: "components",
    },
  },
  data: function() {
    return {
      activeTab: "html",
      showCode: false,
      commitID: "",
      responseObjectMapping: {},
      dataFethed: false,
      componentsObject: {
        card: {
          html: ` <a href="#" target="" class="card white hover-shadow">
        <div class="card__img">
          <img class="img-cover img-top" src="IMAGE_PATH" alt="ALT_TEXT" />
        </div>
        <div class="card__content">
          <header>
            <div class="card__title semibold">Using Ohio Applications</div>
          </header>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit dignissimos animi
            vel dolores.
          </p>
        </div>
      </a>`,
        },
        "card-icon": {
          html: `<a
  href="#"
  target=""
  class="card white fluid"
>
  <div class="card__img external icon red-700">
    <img src="IMAGE_PATH" alt="ALT_TEXT" />
  </div>
  <div class="card__content">
    <header>
      <div class="card__title semibold">WebAIM Contrast Checker</div>
    </header>
    <p>Contrast and Color Accessibility</p>
  </div>
</a>
`,
        },
        banner: {
          html: `<div class="container flex even-cols flex-row-reverse height-full">
  <div class="container line-height-none">
    <img class="img-cover" src="IMAGE_PATH" alt="ALT_TEXT" />
  </div>

  <div class="container red-700 flex justify-center align-center">
    <div class="container min-w400 font-white p-60">
      <header>
        <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, quam?
        </h3>
      </header>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus
        sit qui rem quidem quibusdam, aut magni debitis excepturi deserunt non
        dolores. Qui debitis aperiam nam.
      </p>

      <button
        class="secondary border-white mt-30 rounded-full hover-white-black"
      >
        <div class="btn-txt font-white">Open Application</div>
      </button>
    </div>
  </div>
</div>
`,
          css: ``,
          js: "// No JS code needed",
        },
        comingComponent: {
          html: `<h3>Component preview coming soon</h3>`,
          css: "/* Component preview coming soon */",
        },
        "default-modal": {
          html: `<section>
            <div class="content-band">
              <div class="container flex justify-center mt-40 flex-wrap gap-10">
                <a id="button01" href="#modal-3" role="button" class="primary button black rounded-full modalTrigger" tabindex="0">
                  <div class="btn-txt font-white">Default Modal</div>
                </a>
              </div>
            </div>
          </section>

          <!-- modal -->
          <div id="modal-3" class="modal-wrapper" role="dialog" aria-labelledby="modal-3-heading"
              aria-describedby="modal-3-description">
              <div class="modal-body rounded-20 white text-center">
                  <div class="modal-content">
                      <header>
                          <div class="modal-title" id="modal-3-heading">
                              Open modal with forced action
                          </div>
                      </header>
                      <p id="modal-3-description">
                          Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt
                          ut labore. Quis ipsum suspendisse ultrices gravida.
                      </p>
                      <div class="modal-button-container">
                          <button class="secondary border-iop-drk-blue border-thick rounded-full" onclick="location.href='#!'"
                              aria-label="Sign out">
                              <div class="btn-txt font-iop-drk-blue">Sign out</div>
                              <div class="icon icon-iop-drk-blue">
                                  <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 15.5 15.5">
                                      <path class="icon-svg" d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                                  0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                                  0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"></path>
                                  </svg>
                              </div>
                          </button>
                          <button class="primary iop-drk-blue rounded-full" aria-label="Open Application">
                              <div class="btn-txt font-white">Open Application</div>
                              <div class="icon icon-white">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" width="16">
                                      <path class="icon-svg" d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                                  .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                                  16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                                  8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                                  12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                                  8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                                  12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                                  8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                                  0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"></path>
                                  </svg>
                              </div>
                          </button>
                      </div>
                  </div>
                  <a href="#!" role="button" class="modal_close" aria-label="Close this window">close</a>
              </div>
              <a href="#!" class="modal-outside-trigger"></a>
          </div>`,
        },
        "modal-forced-action": {
          html: `<section>
            <div class="content-band">
              <div class="container flex justify-center mt-40 flex-wrap gap-10">
                <a href="#modal-2" role="button" class="primary button black rounded-full modalTrigger" tabindex="0">
                    <div class="btn-txt font-white">Modal with forced action</div>
                </a>
              </div>
            </div>
          </section>

          <!-- modal -->
            <div id="modal-2" class="modal-wrapper" role="dialog" aria-labelledby="modal-2-heading"
              aria-describedby="modal-2-description">
              <div class="modal-body rounded-20 white text-center" tabindex="-1">
                  <div class="modal-img-container rounded-top-20 h200" style="background: #f0f4f7;">
                      <img class="img-top" src="images/file-tower.jpg" alt="">
                  </div>
                  <div class="modal-content">
                      <header>
                          <div class="modal-title" id="modal-2-heading">
                              Open modal with forced action
                          </div>
                      </header>
                      <p id="modal-2-description">
                          Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt
                          ut labore. Quis ipsum suspendisse ultrices gravida.
                      </p>
                      <div class="modal-button-container">
                          <button class="secondary border-iop-drk-blue border-thick rounded-full" onclick="location.href='#!'"
                              aria-label="Sign out">
                              <div class="btn-txt font-iop-drk-blue">Sign out</div>
                              <div class="icon icon-iop-drk-blue">
                                  <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 15.5 15.5">
                                      <path class="icon-svg" d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                                  0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                                  0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"></path>
                                  </svg>
                              </div>
                          </button>
                          <button class="primary iop-drk-blue rounded-full" aria-label="Open Application">
                              <div class="btn-txt font-white">Open Application</div>
                              <div class="icon icon-white">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" width="16">
                                      <path class="icon-svg" d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                                  .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                                  16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                                  8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                                  12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                                  8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                                  12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                                  8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                                  0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"></path>
                                  </svg>
                              </div>
                          </button>
                      </div>
                  </div>
              </div>
              <a href="#!" class="modal-outside-trigger"></a>
          </div>`,
        },
        "modal-circular-image": {
          html: `<section>
            <div class="content-band">
              <div class="container flex justify-center mt-40 flex-wrap gap-10">
                <a href="#modal-1" role="button" class="primary button black rounded-full modalTrigger" tabindex="0">
                    <div class="btn-txt font-white">Modal with circular image</div>
                </a>
              </div>
            </div>
          </section>

          <!-- modal -->
          <div id="modal-1" class="modal-wrapper" role="dialog" aria-labelledby="modal-1-heading"
              aria-describedby="modal-1-description" aria-hidden='true' tabindex="0">
              <div class="modal-body rounded-20 white text-center">
                  <div class="modal-img-container mb-60">
                      <div class="modal-img-circle overlap-top">
                          <img src="images/avatar.jpg" alt="">
                      </div>
                  </div>
                  <div class="modal-content">
                      <header>
                          <div class="modal-title" id="modal-1-heading">
                              Success, <br>New User Created
                          </div>
                      </header>
                      <p id="modal-1-description">
                          Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt
                          ut labore. Quis ipsum suspendisse ultrices gravida.
                      </p>
                      <div class="modal-button-container">
                          <button id="" class="secondary border-iop-drk-blue border-thick rounded-full"
                              aria-label="Close Application">
                              <div class="btn-txt font-iop-drk-blue">Close Application</div>
                              <div class="icon icon-iop-drk-blue">
                                  <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 15.5 15.5">
                                      <path class="icon-svg" d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                                      0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                                      0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"></path>
                                  </svg>
                              </div>
                          </button>
                          <button id="" class="primary iop-drk-blue rounded-full" aria-label="Open Application">
                              <div class="btn-txt font-white">Open Application</div>
                              <div class="icon icon-white">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" width="16">
                                      <path class="icon-svg" d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                                      .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                                      16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                                      8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                                      12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                                      8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                                      12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                                      8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                                      0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"></path>
                                  </svg>
                              </div>
                          </button>
                      </div>
                  </div>
                  <a href="#!" role="button" class="modal_close modalkill" aria-label="Close this window">close</a>
              </div>
              <a href="#!" class="modal-outside-trigger modalkill"></a>
          </div>`,
        },
        "modal-header-image": {
          html: `<section>
            <div class="content-band">
              <div class="container flex justify-center mt-40 flex-wrap gap-10">
                <a href="#modal-2" role="button" class="primary button black rounded-full modalTrigger" tabindex="0">
                    <div class="btn-txt font-white">Modal with Header image</div>
                </a>
              </div>
            </div>
          </section>

          <!-- modal -->
          <div id="modal-2" class="modal-wrapper" role="dialog" aria-labelledby="modal-2-heading"
            aria-describedby="modal-2-description">
            <div class="modal-body rounded-20 white text-center" tabindex="-1">
                <div class="modal-img-container rounded-top-20 h200" style="background: #f0f4f7;">
                    <img class="img-top" src="images/file-tower.jpg" alt="">
                </div>
                <div class="modal-content">
                    <header>
                        <div class="modal-title" id="modal-2-heading">
                            Open modal with forced action
                        </div>
                    </header>
                    <p id="modal-2-description">
                        Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt
                        ut labore. Quis ipsum suspendisse ultrices gravida.
                    </p>
                    <div class="modal-button-container">
                        <button class="secondary border-iop-drk-blue border-thick rounded-full" onclick="location.href='#!'"
                            aria-label="Sign out">
                            <div class="btn-txt font-iop-drk-blue">Sign out</div>
                            <div class="icon icon-iop-drk-blue">
                                <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 15.5 15.5">
                                    <path class="icon-svg" d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                                0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                                0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"></path>
                                </svg>
                            </div>
                        </button>
                        <button class="primary iop-drk-blue rounded-full" aria-label="Open Application">
                            <div class="btn-txt font-white">Open Application</div>
                            <div class="icon icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" width="16">
                                    <path class="icon-svg" d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                                .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                                16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                                8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                                12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                                8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                                12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                                8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                                0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"></path>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <a href="#!" class="modal-outside-trigger"></a>
        </div>`,
        },
        "primary-button-regular": {
          html: `<button id="" class="rounded-full hover-red hover-font-white red-100">
                    <div class="btn-txt font-red">Open Application</div>
                    <div class="icon icon-red">
                        <i class="fas fa-calendar-alt"></i>
                    </div>
                </button> `,
        },
        "primary-button-disabled": {
          html: `<button disabled id="" class="black rounded-full hover-red disabled">
                      <div class="btn-txt font-white">Close Application</div>
                      <div class="icon icon-white">
                          <i class="fas fa-calendar-alt"></i>
                      </div>
                  </button> `,
        },
        "secondary-button-regular": {
          html: `<button id="" class="secondary border-red rounded-full hover-red hover-font-white flex-row-reverse">
                      <div class="btn-txt font-red">Close Application</div>
                      <div class="icon icon-red font-20">
                          <i class="fas fa-arrow-circle-right"></i>
                      </div>
                  </button> `,
        },
        "secondary-button-disabled": {
          html: `<button disabled class="secondary border-red  rounded-full hover-red flex-row-reverse disabled">
                      <div class="btn-txt font-red">Close Application</div>
                      <div class="icon icon-red">
                          <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 15.5 15.5">
                          <path class="icon-svg" d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"></path></svg>
                      </div>
                  </button> `,
        },
        "text-button-regular": {
          html: `<button class="rounded-4 hover-gray hover-font-red">
                      <div class="btn-txt">Text Button</div>
                  </button>`,
        },
        "text-button-disabled": {
          html: `<button disabled class="rounded-4 hover-gray hover-font-red disabled">
                      <div class="btn-txt">Text Button</div>
                  </button>`,
        },
        "large-tile": {
          html: ` <section>
            <div class="content-band flex flex-col max-w1440">
                <div class="container mb-30">
                    <h1 class="">Tiles</h1>
                </div>

                <div class="container even-cols flex-wrap gap-20">

                    <a href="#" class="tile white hover-gray-300">
                        <div class="tile_img"><img src="images/accessibility-standards-icon.png" alt="Foundations Icon"></div>
                        <div class="tile_content">
                          <header>
                            <div class="tile_title">Visual Elements</div>
                          </header>
                            <p>
                                The fundamental visual elements that provide structure
                                and consistency to state digital experiences. Lorem ipsum dolor set amet
                            </p>
                        </div>
                    </a>

                    <a href="#" class="tile white hover-gray-300">
                        <div class="tile_img"><img src="images/iconography-icon.png" alt="Foundations Icon"></div>
                        <div class="tile_content">
                          <header>
                            <div class="tile_title">Foundations</div>
                          </header>
                            <p>
                                The fundamental visual elements that provide structure
                                and consistency to state digital experiences.
                            </p>
                        </div>
                    </a>
                </div>
                </div>
            </section>`,
        },
        "small-tile-regular": {
          html: `<section>
            <div class="content-band flex flex-col max-w1440">
                <div class="container">
                    <div class="font-28">Small Tiles</div>
                </div>
                <div class="container flex-grid gap-20 flex-row-mobile">
                    <a href="#" class="tile-sml white hover-gray-300 flex-col-reverse rounded-8">
                        <div class="tile__title text-center">Typography</div>
                        <div class="tile-sml__img-container">
                            <img src="images/typography.png" alt="Typography icon">
                        </div>
                    </a>
                </div>
            </div>
        </section>`,
        },
        "small-tile-disabled": {
          html: `<section>
                <div class="content-band flex flex-col max-w1440">
                    <div class="container">
                        <div class="font-28">Small Tiles</div>
                    </div>
                    <div class="container flex-grid gap-20 flex-row-mobile">
                        <div class="tile-sml white rounded-8 gray-300">
                            <div class="tile-sml__img-container">
                                <img src="images/comonents_icon.png" alt="Building blocks icon">
                            </div>
                            <div class="tile__title text-center">Building blocks</div>
                        </div>
                    </div>
                </div>
            </section>`,
        },
        divider: {
          html: `<div class="divider"></div>`,
        },
      },
    };
  },
  components: {
    Prism,
  },
  created: function() {
    // fetch(
    //   "https://665r4wa1oi.execute-api.us-west-2.amazonaws.com/ds/devportal-to-lambda/test"
    // ),
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
    //     },
    //     body: JSON.stringify({
    //       component: "1.0.0/src/components/button/primary-button-disabled.html",
    //     }),
    //   };
  },
  methods: {
    setActiveTab: function(tab) {
      this.activeTab = tab;
    },
    onCopy: function(e) {
      console.log("you just copied " + e.text);
    },
    onError: function(e) {
      console.log(e);
    },
    handleCopy: function() {
      let codeToCopy;
      if (this.activeTab === "html") {
        codeToCopy = this.componentsObject[this.id].html;
      } /*  else if (this.activeTab === "css") {
        codeToCopy = this.componentsObject[this.id].css;
      } else {
        codeToCopy = this.componentsObject[this.id].js;
      } */

      // this.$copyText(codeToCopy).then(
      //   function(e) {
      //     alert("Copied");
      //     console.log(e);
      //   },
      //   function(e) {
      //     alert("Can not copy");
      //     console.log(e);
      //   }
      // );
      this.$copyText(codeToCopy).then(
        function() {
          console.log("Copied");
        },
        function() {
          console.warn("Can not copy");
        }
      );
    },
   /*  handleCodeRequest: function() {
      axios
        .get(
          "https://ds.iop.ohio.gov/1.0.0/components/button/primary-button-regular.html/1.0.0/components/button/primary-button-regular.html",
          {
            headers: {
              //"Content-Type": "application/json",

              "Access-Control-Allow-Origin": "*",

              "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
            },
          }
        )
        .then((response) => console.log(response));
    }, */
    handleCodeRequest: function() {
      this.showCode = !this.showCode;
      let apiPath;
      if (!this.responseObjectMapping[this.type]) {
        if(window.location.host=='devportal.iop.ohio.gov'){
          apiPath = "https://ds.iop.ohio.gov";
        }
        else {
          apiPath = "https://ds-stg.iop.ohio.gov";
        }
        axios
          .get(apiPath + `/1.0.0/${this.block}/${this.component}/${this.type}.html` ,
           // "https://665r4wa1oi.execute-api.us-west-2.amazonaws.com/ds/devportal-to-lambda/whatever",
/*             JSON.stringify({
              component: `/1.0.0/${this.block}/${this.component}/${this.type}.html`,
            }) */
          )
          .then((response) => {
            console.log(response);
            this.$set(this.responseObjectMapping, this.type, response.data);
            this.dataFethed = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
/* width 
::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}*/
/* Track 
::-webkit-scrollbar-track {
  background: black;
  border-radius: 10px;
}*/
/* Handle 
::-webkit-scrollbar-thumb {
  background: #bababa;
  border-radius: 10px;
}*/
/* Handle on hover 


::-webkit-scrollbar-thumb:hover {
  background: gray;
}
::-webkit-scrollbar-corner {
  color: black;
}
.language-html::-webkit-scrollbar:horizontal {
  height: 10px;
  width: 90px;
}
*/
</style>
